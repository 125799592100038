import ConfirmModal from '@/Components/Common/ConfirmModal.jsx';
import PostCodeField from '@/Components/Fields/PostCodeField.jsx';
import RadioGroupField from '@/Components/Fields/RadioGroupField.jsx';
import {FormControlLabel, MenuItem, Radio, TextField} from '@mui/material';
import React, {memo} from 'react';

const profiles_options = [
    {label: 'Premier achat', value: 0},
    {label: 'Investissement à but locatif', value: 2},
    {label: 'Autre', value: 1},
];

const civilities_options = [
    {label: 'Madame', value: 'Madame'},
    {label: 'Monsieur', value: 'Monsieur'},
    {label: 'Laisser vide', value: ''},
];

const ModalProspectForm = ({open, data, loading, errors, onChange, onConfirm, onClose}) => {
    const modal_id = 'modal-prospect-form';
    //useRenderCount('ModalProspectForm', data);

    const handleClose = () => {
        onClose();
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        onChange(name, value);
    };

    const handleControlledChange = ({name, value}) => {
        onChange(name, value);
    };

    return (
        <ConfirmModal
            keepMounted
            id={modal_id}
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
            isForm={true}
            title={<>M'enregistrer</>}
            confirmButtonProps={{
                loading,
            }}
            cancelButtonProps={{
                loading,
            }}
        >
            <RadioGroupField
                fullWidth
                error={!!errors.civility}
                helperText={errors.civility}
                id={modal_id + '-civility'}
                name="civility"
                sx={{mb: 2}}
                RadioGroupProps={{
                    row: true,
                    value: data.civility,
                    onChange: handleChange,
                }}
            >
                {civilities_options.map(({label, value}) => (
                    <FormControlLabel key={label} value={value} label={label} control={<Radio />} />
                ))}
            </RadioGroupField>

            <TextField
                id={modal_id + '-firstname'}
                name="firstname"
                value={data.firstname}
                fullWidth
                required
                autoComplete="name"
                label="Prénom"
                error={!!errors?.firstname}
                helperText={errors.firstname}
                sx={{mb: 2}}
                onChange={handleChange}
            />
            <TextField
                id={modal_id + '-lastname'}
                name="lastname"
                value={data.lastname}
                fullWidth
                required
                autoComplete="name"
                label="Nom"
                error={!!errors?.lastname}
                helperText={errors.lastname}
                sx={{mb: 2}}
                onChange={handleChange}
            />

            <TextField
                id={modal_id + '-phone'}
                type="number"
                name="phone"
                value={data.phone}
                fullWidth
                required
                autoComplete="tel"
                label="Téléphone"
                error={!!errors?.phone}
                helperText={errors.phone}
                sx={{mb: 2}}
                onChange={handleChange}
            />

            <TextField
                id={modal_id + '-email'}
                type="email"
                name="email"
                value={data.email}
                fullWidth
                required
                autoComplete="username"
                label="Adresse email"
                error={!!errors?.email}
                helperText={errors.email}
                sx={{mb: 2}}
                onChange={handleChange}
            />

            <PostCodeField
                id={modal_id + '-postcode'}
                value={data.postcode}
                fullWidth
                required
                label="Code postal"
                error={!!errors?.postcode}
                helperText={errors.postcode}
                sx={{mb: 2}}
                onChange={handleControlledChange}
            />

            <TextField
                id={modal_id + '-city'}
                name="city"
                value={data.city}
                fullWidth
                required
                label="Ville"
                error={!!errors?.city}
                helperText={errors.city}
                sx={{mb: 2}}
                onChange={handleChange}
            />

            <TextField
                select
                id={modal_id + '-profil'}
                name="profil"
                label="Votre profil"
                value={data.profil}
                fullWidth
                error={!!errors?.profil}
                helperText={errors.profil}
                onChange={handleChange}
            >
                {profiles_options.map(({label, value}) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </TextField>
        </ConfirmModal>
    );
};

export default memo(ModalProspectForm);
